import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CustomerConversation } from '@dougs/task/dto';
import { TaskCustomersConversationsComponentService } from '../../../../../services/tasks/task/task-customer-conversations.component.service';

@Component({
  selector: 'dougs-task-customer-conversation-detail',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './task-customer-conversation-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '(click)': 'taskCustomersConversationsComponentService.openConversationNewTab(conversation.url)',
  },
})
export class TaskCustomerConversationDetailComponent {
  constructor(public readonly taskCustomersConversationsComponentService: TaskCustomersConversationsComponentService) {}

  @Input() appearance: 'icon' | 'detail' | 'text' = 'icon';
  @Input({ required: true }) conversation!: CustomerConversation;
}
