import { computed, Inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { CustomerConversation } from '@dougs/task/dto';

@Injectable()
export class TaskCustomersConversationsComponentService {
  constructor(@Inject(Window) private readonly window: Window) {}

  private readonly _conversations$: WritableSignal<CustomerConversation[] | null> = signal(null);
  conversations$: Signal<CustomerConversation[] | null> = this._conversations$.asReadonly();

  setConversations(conversations: CustomerConversation[] | null) {
    this._conversations$.set(conversations);
  }

  customerConversations$: Signal<CustomerConversation[]> = computed(() => this.conversations$() ?? []);
  hasUnSnoozedConversation$: Signal<boolean> = computed(() =>
    this.hasUnSnoozedConversations(this.customerConversations$()),
  );

  private hasUnSnoozedConversations(conversations: CustomerConversation[]): boolean {
    return !!conversations.find((conv) => !conv.snoozed);
  }

  openConversationNewTab(url: string): void {
    this.window.open(url, '_blank');
  }

  onIconClick($event: Event) {
    const conversations = this.customerConversations$();
    if (conversations.length === 1) {
      $event.preventDefault();
      $event.stopPropagation();
      this.openConversationNewTab(conversations[0].url);
    }
  }
}
