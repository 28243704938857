import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { ProductsHttpService } from '../http/products.http';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(
    private readonly httpService: ProductsHttpService,
    private readonly logger: LoggerService,
  ) {}

  async suggestProductForCompany(companyId: number, desiredProduct: string, comment: string): Promise<void> {
    try {
      await lastValueFrom(this.httpService.suggestProductForCompany(companyId, desiredProduct, comment));
    } catch (e) {
      this.logger.error(e);
    }
  }
}
