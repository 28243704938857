@if (suggestProductModalService.refreshPlans$ | async) {}
<form
  [formGroup]="suggestProductModalService.formGroup"
  (ngSubmit)="suggestProductModalService.sendProductSuggestion()"
>
  <div dougsModalTitle>
    <h6>Recommandation - Pack comptabilité</h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div dougsModalContent>
    @if (!suggestProductModalService.isLoading$()) {
      <p class="small">Pack recommandé</p>
      <dougs-form-field>
        <dougs-select dougsFormFieldControl formControlName="desiredProduct" placeholder="Sélectionner un pack">
          @for (plan of suggestProductModalService.plans$ | async; track plan.id) {
            <dougs-select-option [value]="plan.id"> Pack {{ plan.name }} </dougs-select-option>
          }
        </dougs-select>
      </dougs-form-field>
      <dougs-form-field>
        <label dougsFormFieldLabel for="comment">Commentaire</label>
        <textarea
          id="comment"
          placeholder="Entrez votre commentaire"
          formControlName="comment"
          type="text"
          dougsFormFieldControl
        ></textarea>
      </dougs-form-field>
    }
    @if (suggestProductModalService.isLoading$()) {
      <dougs-loader></dougs-loader>
    }
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
    <dougs-button type="submit">Recommander</dougs-button>
  </div>
</form>
