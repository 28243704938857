import { AsyncPipe, CurrencyPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DougsDatePipe } from '@dougs/ds';
import { BillingInvoice, BillingInvoiceItem } from '@dougs/subscription/dto';
import { UserStateService } from '@dougs/user/shared';
import { BillingInvoiceLineComponent } from '../billing-invoice-line/billing-invoice-line.component';

@Component({
  selector: 'dougs-billing-invoice',
  templateUrl: './billing-invoice.component.html',
  styleUrls: ['./billing-invoice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgFor, BillingInvoiceLineComponent, AsyncPipe, CurrencyPipe, DougsDatePipe],
})
export class BillingInvoiceComponent {
  @Input() billingInvoice!: BillingInvoice;
  @Input() displayTitle = false;
  @Input() canAddService = false;
  @Input() forceLongLine = false;
  @Output() addService: EventEmitter<BillingInvoice> = new EventEmitter<BillingInvoice>();
  @Output() removeBillingInvoiceItem: EventEmitter<BillingInvoiceItem> = new EventEmitter<BillingInvoiceItem>();
  @Output() openTaskModal: EventEmitter<number> = new EventEmitter<number>();

  constructor(public readonly userStateService: UserStateService) {}

  public trackById(index: number, item: BillingInvoiceItem): string | undefined {
    return item?.id;
  }
}
