import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ConfigBackService } from '@dougs/core/config-back';
import { User } from '@dougs/user/dto';
import { USER_FLAG, UserStateService } from '@dougs/user/shared';

@Injectable()
export class LegalCollaboratorComponentService {
  constructor(
    private readonly userStateService: UserStateService,
    private readonly configBackService: ConfigBackService,
  ) {}

  isLegalCollaborator$: Observable<boolean> = this.userStateService.loggedInUser$.pipe(
    map((loggedInUser: User) => {
      return loggedInUser?.flags.includes(USER_FLAG.CAN_SEE_TASK_LEGAL_STATE) || loggedInUser?.department === 'legal';
    }),
  );

  canSeeTaskTodoList$: Observable<boolean> = this.userStateService.loggedInUser$.pipe(
    map((loggedInUser: User) => loggedInUser?.flags.includes(USER_FLAG.CAN_SEE_TASK_MODULE_TODO_LIST)),
  );
}
