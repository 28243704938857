import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isAfter } from 'date-fns';
import { lastValueFrom } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { RoutingService, URL } from '@dougs/core/routing';
import { mergeObjects, toPromise } from '@dougs/core/utils';
import { ModalService, OverlayCloseEvent, Pill } from '@dougs/ds';
import { SettingsRouteService } from '@dougs/settings-new/shared';
import { Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { CompletionModalComponent } from '../../modals';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerCompanyCompletion extends TaskActionService {
  protected _isAvailable = true;
  protected _label = 'Remplir mes paramètres';
  protected _tag = 'Paramètres';
  protected _tagColor: Pill = 'admin';
  protected _tagIcon = 'fa-cog';

  constructor(
    private readonly modalService: ModalService,
    private readonly companyStateService: CompanyStateService,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly router: Router,
    private readonly routingService: RoutingService,
    private readonly settingsRouteService: SettingsRouteService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }

    const canAccessNewSettings: boolean = await toPromise(this.settingsRouteService.canAccessNewSettings$);

    await this.router.navigateByUrl(
      this.routingService.createUrl([canAccessNewSettings ? URL.SETTINGS_NEW : URL.SETTINGS]).toString(),
    );
  }

  async automaticallyExecute(task: Task | null): Promise<void> {
    if (!this.companyStateService.activeCompany?.subscription?.activatedAt || !task) {
      return;
    }

    if (
      !task ||
      !task.metadata ||
      (task.metadata.completion?.details?.accounting?.percent &&
        task.metadata.completion?.details?.accounting?.percent >= 100)
    ) {
      return;
    }

    const shouldShowModal: boolean =
      !task.metadata.completionModalLastSeenAt ||
      isAfter(new Date(task.metadata.completionModalLastSeenAt), new Date());
    if (shouldShowModal) {
      const modalResult: OverlayCloseEvent<boolean | null | undefined> = await lastValueFrom(
        this.modalService.open<boolean>(CompletionModalComponent, {
          data: task,
        }).afterClosed$,
      );
      if (modalResult.data) {
        const updatedTask: Task = mergeObjects(task, {
          metadata: {
            ...task?.metadata,
            completionModalLastSeenAt: new Date(),
          },
        });
        await this.controlPanelTasksStateService.updateTask(updatedTask);
        const canAccessNewSettings: boolean = await toPromise(this.settingsRouteService.canAccessNewSettings$);
        await this.router.navigateByUrl(
          this.routingService.createUrl([canAccessNewSettings ? URL.SETTINGS_NEW : URL.SETTINGS]).toString(),
        );
      }
    }
  }
}
