<div class="task-card mb-24">
  <div
    [ngClass]="[task | taskHeaderClass: (legalCollaboratorComponentService.isLegalCollaborator$ | async)]"
    class="task-card__header p-8"
  >
    <i
      (click)="toggleCompleted()"
      [dougsTooltipCloseOnClick]="true"
      [dougsTooltipDisable]="!task.completedAt"
      [dougsTooltip]="
        (task.completer?.profile?.fullName || 'Auto complétée') + ', ' + (task.completedAt | date: 'dd/MM/yyyy HH:mm')
      "
      [ngClass]="task | toggleIcon"
      class="fal"
    ></i>
    <h6 class="task-card__header__title">
      <span>
        {{ task.title }}
        <i class="fal fa-question-circle" *ngIf="task.hasRemarks" (click)="taskComponentService.showRemarks(task)"></i>
      </span>
      <div class="task-card__header__title__pills">
        <dougs-pill *ngIf="task.abortedAt" type="error">Abandonnée</dougs-pill>
        <dougs-pill *ngIf="task.deletedAt" type="error">Supprimée</dougs-pill>
        <dougs-pill *ngIf="task.quoteStatus === 'project'" type="primary">Projet</dougs-pill>
        <dougs-pill *ngIf="task.quoteStatus === 'quote'" type="warning">Devis</dougs-pill>
      </div>
    </h6>
    <i
      *ngIf="(userStateService.loggedInUser$ | async).accountingFirmId === 1"
      [dougsDropdownTrigger]="actionsDropdown"
      class="fal fa-ellipsis-h"
    ></i>
  </div>
  <dougs-task-metadata [taskTemplate]="taskTemplate" [task]="task"></dougs-task-metadata>
  <div class="task-card__body">
    <div
      *ngIf="legalCollaboratorComponentService.isLegalCollaborator$ | async"
      class="task-card__body__legal-state px-8 py-4"
    >
      <label dougsFormFieldLabel>Etat :</label>
      <dougs-form-field [noMargin]="true" size="small">
        <dougs-select
          dougsFormFieldControl
          size="small"
          placeholder="Non catégorisé"
          (ngModelChange)="taskComponentService.updateLegalState($event, task)"
          [ngModel]="task.legalState"
        >
          <dougs-select-option
            *ngFor="let legalState of TASK_LEGAL_STATE | keyvalue: keepOriginalOrder; trackBy: 'code' | trackBy"
            [value]="legalState.value"
          >
            <p class="small color-primary-700">
              {{ legalState.value | legalState }}
            </p>
          </dougs-select-option>
        </dougs-select>
      </dougs-form-field>
    </div>
    <div
      (ngModelChange)="taskComponentService.updateDueDate($event, task)"
      [dougsDatepicker]="dueDatepicker"
      [dougsDatepickerDisabled]="!isCustomerTask && !(taskComponentService.canUserUpdateDueDate$ | async)"
      [useDefaultTaskPill]="true"
      [ngClass]="{
        'no-border': task.subTaskCount === 0,
        pointer: isCustomerTask || (taskComponentService.canUserUpdateDueDate$ | async)
      }"
      [ngModel]="task.dueDate"
      class="task-card__body__due-date px-8 py-4"
      ngDefaultControl
    >
      Echéance: {{ task.dueDate | date }}
    </div>
    <div
      *ngIf="task.billingInvoiceItems?.length || task.metadata?.isBillable"
      [ngClass]="{ 'is-expanded': taskComponentService.billingInvoicesExpanded$ | async }"
      class="task-card__body__billing-invoice p-8"
    >
      <div
        (click)="taskComponentService.toggleBillingInvoicesExpanded()"
        class="task-card__body__billing-invoice__header"
        [class.pointer]="task.billingInvoiceItems.length"
      >
        <p class="tiny">
          <ng-container *ngIf="task.billingInvoiceItems?.length === 0">
            {{ task.policy.isBillableAfterConfig ? 'Devis automatique' : 'Aucune prestation' }}
          </ng-container>
          <ng-container *ngIf="task.billingInvoiceItems?.length === 1">1 prestation</ng-container>
          <ng-container *ngIf="task.billingInvoiceItems?.length > 1">
            {{ task.billingInvoiceItems.length }} prestations
          </ng-container>
          <span *ngIf="task | billingInvoiceTotalItems as totalItems" class="color-primary-700">
            : {{ totalItems.totalAmount | currency: 'EUR' : 'symbol' : '1.0-2' }} HT</span
          >
        </p>
        <div class="task-card__body__billing-invoice__header__icons">
          <i
            *ngIf="task.metadata?.billOnPendingInvoice"
            [dougsTooltipPosition]="'left'"
            [dougsTooltip]="'Facturé sur la prochaine facture mensuelle'"
            class="fal fa-calendar"
          ></i>
          <i
            *ngIf="task | billingInvoiceIcon as icon"
            [dougsTooltipPosition]="'left'"
            [dougsTooltip]="(task | billingInvoiceTooltip) || ''"
            [ngClass]="[icon, task | billingInvoiceIconColor]"
            class="fal"
          ></i>
          <i
            *ngIf="task.billingInvoiceItems?.length >= 1"
            [ngClass]="{
              'fa-chevron-up': taskComponentService.billingInvoicesExpanded$ | async,
              'fa-chevron-down': !(taskComponentService.billingInvoicesExpanded$ | async)
            }"
            class="fal"
          ></i>
          <i
            *ngIf="task.billingInvoiceItems.length === 0"
            class="pointer"
            (click)="
              task.policy.isBillableAfterConfig
                ? taskComponentService.showHelpModal()
                : billingInvoiceService.addServiceInTaskBillingInvoice(task)
            "
            [ngClass]="task.policy.isBillableAfterConfig ? 'fal fa-question-circle' : 'fal fa-plus'"
          ></i>
        </div>
      </div>
      <div
        *ngIf="(taskComponentService.billingInvoicesExpanded$ | async) && task.billingInvoiceItems.length"
        class="p-4"
      >
        <dougs-divider class="mb-8"></dougs-divider>
        <ng-container *ngFor="let invoiceItem of task.billingInvoiceItems; trackBy: trackById; let last = last">
          <div [ngClass]="{ 'mb-4': !last }" class="invoice-line" *ngIf="invoiceItem">
            <div class="invoice-line__description">{{ invoiceItem.fullDescription }}</div>
            <div class="invoice-line__divider">
              <dougs-divider></dougs-divider>
            </div>
            <div class="invoice-line__amount">
              {{ invoiceItem.totalAmount | currency: 'EUR' : 'symbol' : '1.0-2' }}
              <i
                (click)="taskComponentService.deleteService(task, invoiceItem)"
                *ngIf="task | isEditableService: invoiceItem"
                class="fal fa-times ml-4"
              ></i>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="userStateService.loggedInUser$ | async as loggedInUser">
          <div
            *ngIf="loggedInUser?.isAccountantOrAdmin && (task | isEditableService)"
            class="mt-8 task-card__body__billing-invoice__add-item"
          >
            <p (click)="billingInvoiceService.addServiceInTaskBillingInvoice(task)" class="color-gray-325 tiny pointer">
              + Ajouter une prestation
            </p>
          </div>
        </ng-container>
        <div *ngIf="task | billingInvoiceTotalItems as totalItems" class="billing-invoice-total__container mt-8">
          <div class="billing-invoice-total__ht">
            <div class="billing-invoice-total__ht__title">
              <p class="color-primary-700 tiny bold">Total HT</p>
            </div>
            <div class="billing-invoice-total__ht__amount">
              <p class="tiny color-primary-700 bold">{{ totalItems.totalAmount | currency: 'EUR' }}</p>
            </div>
          </div>
          <div class="billing-invoice-total__ttc">
            <p class="billing-invoice-total__ttc__title tiny">Total TTC</p>
            <p class="billing-invoice-total__ttc__amount tiny">
              {{ totalItems.totalAmountIncludingTaxes | currency: 'EUR' }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="task.hasForm" class="task-card__body__configuration p-8">
      <p (click)="toggleConfigurationExpanded()" class="task-card__body__configuration__header tiny pointer">
        Configuration
        <i
          [ngClass]="{
            'fa-chevron-up': taskComponentService.configurationExpanded$ | async,
            'fa-chevron-down': !(taskComponentService.configurationExpanded$ | async)
          }"
          class="fal ml-4"
        ></i>
      </p>
      <div *ngIf="(taskComponentService.configurationExpanded$ | async) && task.hasForm && form" class="mt-4">
        <dougs-task-form-fields
          (cancelTaskForm)="cancelTaskForm()"
          (submitTaskForm)="updateTaskForm()"
          (changeTaskForm)="changeTaskForm()"
          [taskFormInlineGroup]="form.inlineGroup"
          [task]="task"
          size="small"
        ></dougs-task-form-fields>
      </div>
    </div>
    <div
      (click)="taskComponentService.toggleSubTasksExpanded(task)"
      *ngIf="task.subTaskCount > 0"
      class="task-card__body__sub-task p-8"
    >
      <div class="task-card__body__sub-task__title">
        <div class="mr-4">({{ task.completedSubTaskCount }}/{{ task.subTaskCount }})</div>
        <div class="task-card__body__sub-task__title__active color-primary-700">
          {{ task | activeSubtaskTitle }}
        </div>
      </div>
      <div class="task-card__body__sub-task__icon">
        <i
          class="fal ml-4"
          [ngClass]="{
            'fa-chevron-up': taskComponentService.subTasksExpanded$ | async,
            'fa-chevron-down': !(taskComponentService.subTasksExpanded$ | async)
          }"
        ></i>
      </div>
    </div>
    <ng-container *ngIf="taskComponentService.subTasksExpanded$ | async">
      <dougs-task-item
        [taskTemplate]="taskTemplate"
        *ngFor="let taskItem of task.tasks; trackBy: 'id' | trackBy"
        [task]="taskItem"
      />
    </ng-container>
  </div>
  <div class="task-card__footer">
    <dougs-task-files [taskTemplate]="taskTemplate" [task]="task"></dougs-task-files>
    @if ((legalCollaboratorComponentService.canSeeTaskTodoList$ | async) && task.customerConversations?.length) {
      <div class="task-card__footer__customer-conversations">
        <dougs-task-customer-conversations
          class="task-customer-conversations"
          [conversations]="task.customerConversations"
          appearance="detail"
        />
      </div>
    }
    <dougs-task-conversation [task]="task"></dougs-task-conversation>
  </div>
</div>
<dougs-dropdown #actionsDropdown [widthToRefElement]="false">
  <dougs-dropdown-option
    (click)="taskComponentService.openAskCustomerInformationModal(task)"
    *ngIf="showAskClientInformation"
    [closeOnClick]="true"
  >
    <i class="fal fa-envelope mr-8"></i>
    Envoyer une demande au client
  </dougs-dropdown-option>
  <dougs-dropdown-option
    (click)="taskComponentService.makeDocumentsAvailable(task, taskTemplate)"
    [disabled]="makeDocumentsAvailableDisabled"
    [dougsTooltip]="task | makeDocumentsAvailableTooltip"
    [dougsTooltipDisable]="!makeDocumentsAvailableDisabled"
    [closeOnClick]="true"
  >
    <i class="fal fa-share-from-square mr-8"></i>
    Mettre à dispo. des docs temporaires
  </dougs-dropdown-option>
  <dougs-dropdown-option
    (click)="taskComponentService.openYousignModal(task)"
    [closeOnClick]="true"
    *ngIf="task.department === 'legal'"
  >
    <i class="fal fa-circle-plus mr-8"></i>
    Créer une procédure Yousign
  </dougs-dropdown-option>
  <dougs-dropdown-option
    (click)="taskComponentService.openInvoiceSettings(task)"
    *ngIf="task.metadata?.isBillable"
    [closeOnClick]="true"
  >
    <i class="fal fa-gear mr-8"></i>
    Paramètres devis/facturation
  </dougs-dropdown-option>
  <dougs-dropdown-option
    *ngIf="(userStateService.loggedInUser$ | async).flags.includes('canUpdateTaskWorkload')"
    (click)="taskComponentService.openWorkloadModal(task)"
    [closeOnClick]="true"
  >
    <i class="fal fa-pen mr-8"></i>
    Modifier la charge de travail
  </dougs-dropdown-option>
  @if ((legalCollaboratorComponentService.canSeeTaskTodoList$ | async) && taskSnoozeComponentService.isSnoozed$()) {
    <dougs-dropdown-option (click)="taskSnoozeComponentService.unSnooze()" [closeOnClick]="true">
      <i class="fal fa-alarm-snooze mr-8"></i>
      Désnoozer la tâche
    </dougs-dropdown-option>
  }
  <dougs-dropdown-option (click)="taskComponentService.togglePriority(task)" [closeOnClick]="true">
    <i class="fal fa-star mr-8"></i>
    {{ task.isPriority ? 'Supprimer la priorité' : 'Marquer comme prioritaire' }}
  </dougs-dropdown-option>
  <dougs-dropdown-option (click)="taskComponentService.openActivityModal(task)" [closeOnClick]="true">
    <i class="fal fa-eye mr-8"></i>
    Voir l'activité
  </dougs-dropdown-option>
  <dougs-dropdown-option (click)="taskComponentService.showRemarks(task)" *ngIf="task.hasRemarks" [closeOnClick]="true">
    <i class="fal fa-eye mr-8"></i>
    Voir les remarques
  </dougs-dropdown-option>
  <dougs-dropdown-option (click)="taskComponentService.copyLink(task)" [closeOnClick]="true">
    <i class="fal fa-copy mr-8"></i>
    Copier le lien de la tâche
  </dougs-dropdown-option>
  <dougs-dropdown-option (click)="taskComponentService.restoreTask(task)" *ngIf="task.abortedAt" [closeOnClick]="true">
    <i class="fal fa-check mr-8"></i>
    <span class="color-primary">Réactiver la tâche</span>
  </dougs-dropdown-option>
  <dougs-dropdown-option (click)="abortTask()" *ngIf="!task.abortedAt" [closeOnClick]="true">
    <span class="color-error">
      <i class="fal fa-trash-can mr-8"></i>
      Abandonner la tâche
    </span>
  </dougs-dropdown-option>
  <dougs-dropdown-option
    (click)="deleteTask()"
    *ngIf="
      !task.deletedAt &&
      (userStateService.loggedInUser$ | async)?.isAccountantOrAdmin &&
      (userStateService.loggedInUser$ | async)?.flags?.includes(USER_FLAG.CAN_DELETE_TASK)
    "
    [closeOnClick]="true"
  >
    <span class="color-error">
      <i class="fal fa-trash-can mr-8"></i>
      Supprimer la tâche
    </span>
  </dougs-dropdown-option>
</dougs-dropdown>
<dougs-datepicker #dueDatepicker></dougs-datepicker>
