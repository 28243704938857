import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingService, URL } from '@dougs/core/routing';
import { toPromise } from '@dougs/core/utils';
import { ECOMMERCE_SETTINGS_URL, SettingsRouteService } from '@dougs/settings-new/shared';
import { Task } from '@dougs/task/dto';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerSalesViaPlatformSurveyService extends TaskActionService {
  protected _label = 'Répondre au questionnaire';
  protected _isAvailable = true;

  constructor(
    private readonly router: Router,
    private readonly routingService: RoutingService,
    private readonly settingsRouteService: SettingsRouteService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    const canAccessNewSettings: boolean = await toPromise(this.settingsRouteService.canAccessNewSettings$);
    await this.router.navigateByUrl(
      this.routingService
        .createUrl(
          canAccessNewSettings
            ? [URL.SETTINGS_NEW, ECOMMERCE_SETTINGS_URL.ECOMMERCE, ECOMMERCE_SETTINGS_URL.ECOMMERCE_TAX_FORM]
            : [URL.SETTINGS],
        )
        .toString(),
    );
  }
}
