import { CommonModule, CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  DividerComponent,
  FormFieldComponent,
  LabelFormFieldDirective,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  PanelInfoComponent,
} from '@dougs/ds';
import { BillingInvoiceItem } from '@dougs/subscription/dto';
import { AddBillingInvoiceItemModalComponentService } from './add-billing-invoice-item-modal.component.service';
import { BillableItemAmountIncludingTaxesPipe } from './billable-item-amount-including-taxes.pipe';
import { BillableItemAmountPipe } from './billable-item-amount.pipe';
import { SearchInvoiceComponent } from './search-invoice/search-invoice.component';

@Component({
  selector: 'dougs-add-billing-invoice-item-modal',
  templateUrl: './add-billing-invoice-item-modal.component.html',
  styleUrls: ['./add-billing-invoice-item-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CurrencyPipe, BillableItemAmountPipe, AddBillingInvoiceItemModalComponentService],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FormFieldComponent,
    LabelFormFieldDirective,
    ControlFormFieldDirective,
    ModalTitleDirective,
    ModalContentDirective,
    ModalCloseDirective,
    ModalFooterDirective,
    DividerComponent,
    ButtonComponent,
    BillableItemAmountPipe,
    BillableItemAmountIncludingTaxesPipe,
    SearchInvoiceComponent,
    PanelInfoComponent,
  ],
})
export class AddBillingInvoiceItemModalComponent implements OnInit {
  constructor(
    private readonly cdr: ChangeDetectorRef,
    public readonly addBillingInvoiceItemModalComponentService: AddBillingInvoiceItemModalComponentService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.addBillingInvoiceItemModalComponentService.populateFormGroup();
    this.cdr.markForCheck();
  }

  async openServicesModal(): Promise<void> {
    await this.addBillingInvoiceItemModalComponentService.openServicesModal();
    this.cdr.markForCheck();
  }

  trackById(index: number, item: BillingInvoiceItem): string {
    return item.id || item.billableServiceId;
  }

  trackByIndex(index: number): number {
    return index;
  }
}
