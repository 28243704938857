import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DropdownComponent, DropdownOptionComponent, DropdownTriggerDirective } from '@dougs/ds';
import { CustomerConversation } from '@dougs/task/dto';
import { TaskCustomersConversationsComponentService } from '../../../../../services/tasks/task/task-customer-conversations.component.service';
import { TaskCustomerConversationDetailComponent } from '../task-customer-conversation-detail/task-customer-conversation-detail.component';

@Component({
  selector: 'dougs-task-customer-conversations',
  standalone: true,
  imports: [
    CommonModule,
    DropdownComponent,
    DropdownOptionComponent,
    DropdownTriggerDirective,
    TaskCustomerConversationDetailComponent,
  ],
  templateUrl: './task-customer-conversations.component.html',
  styleUrl: './task-customer-conversations.component.scss',
  providers: [TaskCustomersConversationsComponentService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskCustomerConversationsComponent {
  constructor(public readonly taskCustomersConversationsComponentService: TaskCustomersConversationsComponentService) {}

  @Input() appearance: 'icon' | 'detail' | 'text' = 'icon';
  @Input({ required: true }) set conversations(conversations: CustomerConversation[] | undefined) {
    this.taskCustomersConversationsComponentService.setConversations(conversations ?? null);
  }
}
