import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';

@Injectable({
  providedIn: 'root',
})
export class ProductsHttpService {
  constructor(private readonly http: HttpClient) {}

  suggestProductForCompany(companyId: number, desiredProduct: string, comment: string): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/products/create-opportunity`,
      { desiredProduct, comment },
    );
  }
}
